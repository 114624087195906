<template>
  <component
    :is="comp"
    v-bind="$attrs"
    v-on="popperListeners"
    ref="popperTriggerEl"
  >
    <slot name="default"></slot>
    <transition name="short-fade">
      <div ref="popperEl" class="card popper-instance" v-show="showed">
        <slot name="popper"></slot>
        <div class="popper-arrow" data-popper-arrow></div>
      </div>
    </transition>
  </component>
</template>

<script>
import { createPopper } from "@popperjs/core";

export default {
  name: "Popper",
  props: {
    comp: {
      type: String,
      default: () => "span"
    },
    myListeners: {
      type: Boolean,
      default: () => false
    },
    modifiers: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    popperInstance: null,
    showed: false,
    defaultModifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8]
        }
      }
      // {
      //   name: "flip",
      //   options: {
      //     fallbackPlacements: ["top", "bottom"]
      //   }
      // }
    ]
  }),
  computed: {
    popperModifiers() {
      return [...this.defaultModifiers, ...this.modifiers];
    },
    popperListeners() {
      if (this.myListeners) return this.$listeners;
      return {
        ...this.$listeners,
        mouseenter: this.show,
        focus: this.show,
        mouseleave: this.hide,
        blur: this.hide
      };
    }
  },
  mounted() {
    this.popperInstance = createPopper(
      this.$refs.popperTriggerEl,
      this.$refs.popperEl,
      { modifiers: this.popperModifiers }
    );
  },
  methods: {
    show() {
      // Make the tooltip visible
      // this.$refs.popperEl.setAttribute("data-show", "");
      this.showed = true;
      // Enable the event listeners
      this.popperInstance.setOptions({
        modifiers: [
          { name: "eventListeners", enabled: true },
          ...this.popperModifiers
        ]
      });

      // Update its position
      this.popperInstance.update();
    },

    hide() {
      // Hide the tooltip
      // this.$refs.popperEl.removeAttribute("data-show");
      this.showed = false;

      // Disable the event listeners
      this.popperInstance.setOptions({
        modifiers: [
          { name: "eventListeners", enabled: false },
          ...this.popperModifiers
        ]
      });
    }
  }
};
</script>

<style lang="scss">
$popper-border-style: $border-width solid $border-color;
$arrow-size: 8px;
$arrow-padding: -$arrow-size / 2;

.popper-arrow {
  &,
  &::before {
    position: absolute;
    width: $arrow-size;
    height: $arrow-size;
    background: inherit;
  }

  visibility: hidden;

  &::before {
    visibility: visible;
    content: "";
    transform: rotate(45deg);
  }
}

.popper-instance {
  z-index: 999999;

  &[data-popper-placement^="top"] > .popper-arrow {
    bottom: $arrow-padding;

    &::before {
      border-bottom: $popper-border-style;
      border-right: $popper-border-style;
    }
  }

  &[data-popper-placement^="bottom"] > .popper-arrow {
    top: $arrow-padding;

    &::before {
      border-top: $popper-border-style;
      border-left: $popper-border-style;
    }
  }

  &[data-popper-placement^="left"] > .popper-arrow {
    right: $arrow-padding;

    &::before {
      border-top: $popper-border-style;
      border-right: $popper-border-style;
    }
  }

  &[data-popper-placement^="right"] > .popper-arrow {
    left: $arrow-padding;

    &::before {
      border-bottom: $popper-border-style;
      border-left: $popper-border-style;
    }
  }
}
</style>
