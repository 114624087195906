<template>
  <div class="card">
    <div class="card-header">
      Email'ы для уведомлений
    </div>
    <div class="card-body">
      <div class="card-text">
        <div v-for="(item, k) in value" :key="k">
          <base-input
            type="email"
            :value="item"
            class="mb-2"
            :validate-disabled="false"
            @input="val => changeItem(k, val)"
            v-if="renderInputs"
          >
            <template v-slot:append>
              <filled-button color="danger" @click.native="deleteItem(k)">
                <SvgIcon icon="trash" color="current" height="16"></SvgIcon>
              </filled-button>
            </template>
          </base-input>
        </div>
        <div class="d-flex w-100 justify-content-center mb-2">
          <filled-button block color="success" @click.native="addItem()">
            <SvgIcon icon="plus" color="current" height="16"></SvgIcon>
          </filled-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "../../components/base/SvgIcon";
import BaseInput from "./BaseInput";
import FilledButton from "../buttons/FilledButton";
export default {
  name: "EmailListInput",
  components: { FilledButton, BaseInput, SvgIcon },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    newValue: [],
    renderInputs: true
  }),
  methods: {
    changeItem(index, value) {
      let outArray = [];
      this.value.forEach((val, ind) => {
        if (index === ind) {
          if (value) outArray.push(value);
          return;
        }
        if (val) outArray.push(val);
      });
      this.$emit("input", outArray);
    },
    addItem() {
      this.$emit("input", [...this.value, ""]);
    },
    deleteItem(index) {
      console.log(index);
      this.$emit(
        "input",
        this.value.filter((_, ind) => {
          let res = index !== ind;
          console.log(index, ind, res);
          return res;
        })
      );
      this.rerenderInputs();
    },
    rerenderInputs() {
      this.renderInputs = false;
      this.$nextTick(() => (this.renderInputs = true));
    }
  }
};
</script>
