<template>
  <div :class="{ 'no-select': hovered }">
    <TitleBold center class="mb-2 cursor-pointer">{{
      eventSlugNow ? $t("event.edit.title") : $t("event.create.title")
    }}</TitleBold>
    <validation-observer ref="observer">
      <div class="row gy-2">
        <div class="col col-12 col-md-6">
          <base-input
            label="Название мероприятия [ru]"
            class="mb-2"
            v-model="eventTitleRu"
            required
            :validate-disabled="false"
            :max-length="300"
          ></base-input>
        </div>
        <div class="col col-12 col-md-6">
          <base-input
            label="Название мероприятия [en]"
            class="mb-2"
            v-model="eventTitleEn"
            :validate-disabled="false"
            :max-length="300"
          ></base-input>
        </div>
        <div class="col col-12 col-md-4">
          <base-input
            label="Короткая ссылка"
            class="mb-2"
            v-model="eventSlug"
            required
            :validate-disabled="false"
            :max-length="16"
          ></base-input>
        </div>
        <div class="col col-12 col-md-4">
          <base-input
            label="Изображение вверху старницы"
            type="file"
            class="mb-2"
            v-model="newHeaderImage"
            :file-types="['image/*']"
            :validate-disabled="false"
          >
            <template v-slot:append>
              <filled-button
                color="warning"
                @click.native="resetHeaderImage"
                v-if="newHeaderImage || deletedHeaderImage"
              >
                <SvgIcon icon="redo" color="current" height="16"></SvgIcon>
              </filled-button>
              <filled-button
                color="danger"
                @click.native="deleteHeaderImage"
                v-if="eventHeaderImage"
              >
                <SvgIcon icon="trash" color="current" height="16"></SvgIcon>
              </filled-button>
            </template>
          </base-input>
        </div>
        <div class="col col-12 col-md-4">
          <base-input
            label="Изображение в списке"
            type="file"
            class="mb-2"
            v-model="newSmallImage"
            :file-types="['image/*']"
            :validate-disabled="false"
          >
            <template v-slot:append>
              <filled-button
                color="warning"
                @click.native="resetSmallImage"
                v-if="newSmallImage || deletedSmallImage"
              >
                <SvgIcon icon="redo" color="current" height="16"></SvgIcon>
              </filled-button>
              <filled-button
                color="danger"
                @click.native="deleteSmallImage"
                v-if="eventSmallImage"
              >
                <SvgIcon icon="trash" color="current" height="16"></SvgIcon>
              </filled-button>
            </template>
          </base-input>
        </div>
        <div class="col col-12 col-md-6">
          <base-input
            label="Даты проведения"
            type="date"
            v-model="eventDates"
            is-range
            class="mb-2"
            required
            :validate-disabled="false"
          ></base-input>
        </div>
        <div class="col col-12 col-md-6">
          <base-input
            label="Даты приема заявок"
            type="date"
            v-model="eventDatesRequest"
            is-range
            class="mb-2"
            required
            :validate-disabled="false"
          ></base-input>
        </div>
        <div class="col col-12">
          <TitleStrip center class="mb-2">отображение в списке</TitleStrip>
          <div
            class="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-0 g-md-2 justify-content-center"
          >
            <div class="col">
              <event-short-info
                :item="eventShortInfo"
                without-link
              ></event-short-info>
            </div>
          </div>
        </div>
        <div class="col col-12">
          <base-input
            type="url"
            label="Сайт"
            class="mb-2"
            v-model="eventSite"
            placeholder="https://bmstu.ru/"
            :validate-disabled="false"
          ></base-input>
        </div>
        <div class="col col-12">
          <email-list-input v-model="eventEmailList"></email-list-input>
        </div>
        <div class="col col-12">
          <base-input
            label="Сторонняя ссылка для подачи заявки"
            type="url"
            help="Заполнять в случае регистрации не через Reg.BMSTU"
            placeholder="https://bmstu.ru/"
            v-model="eventCustomLink"
            class="mb-2"
            :validate-disabled="false"
          ></base-input>
        </div>
        <div class="col col-12">
          <base-input
            label="Описание мероприятия [ru]"
            type="textarea"
            class="mb-2"
            rows="10"
            v-model="eventDescriptionRu"
            required
            :validate-disabled="false"
          ></base-input>
        </div>
        <div class="col col-12">
          <TitleStrip center class="mb-2" v-if="eventDescriptionRuHtml">
            отображение описания [ru]
          </TitleStrip>
          <SmallContainer>
            <div v-html="eventDescriptionRuHtml"></div>
          </SmallContainer>
        </div>
        <div class="col col-12">
          <base-input
            label="Описание мероприятия [en]"
            type="textarea"
            class="mb-2"
            rows="10"
            v-model="eventDescriptionEn"
            :validate-disabled="false"
          ></base-input>
        </div>
        <div class="col col-12">
          <TitleStrip center class="mb-2" v-if="eventDescriptionEnHtml">
            отображение описания [en]
          </TitleStrip>
          <SmallContainer>
            <div v-html="eventDescriptionEnHtml"></div>
          </SmallContainer>
        </div>
        <div class="col col-12 col-md-6">
          <UserSelect v-model="admins" label="Администраторы"></UserSelect>
        </div>
        <div class="col col-12 col-md-6">
          <UserSelect v-model="moderators" label="Организаторы"></UserSelect>
        </div>
        <div class="col col-12 col-md-6">
          <radio-buttons-input
            :items="eventNames"
            v-model="eventNameChosen"
            label="Тип мероприятия"
            class="mb-2"
            required
            button-group
            :inline="true"
            button
            one-value
            :validate-disabled="false"
          >
          </radio-buttons-input>
        </div>
        <div class="col col-12 col-md-6">
          <check-box-group-input
            :items="eventArticleTypes"
            v-model="eventArticleTypeChosen"
            label="Типы участия"
            class="mb-2"
            required
            button-group
            :inline="true"
            button
            :validate-disabled="false"
          >
          </check-box-group-input>
        </div>
        <div class="col col-12 col-md-4">
          <check-box-input
            switcher
            v-model="eventReview"
            label="Рецензирование"
            class="mb-2"
            :validate-disabled="false"
          >
          </check-box-input>
        </div>
        <div class="col col-12 col-md-4">
          <check-box-input
            switcher
            v-model="eventEscortFaces"
            label="Сопровождающие лица"
            class="mb-2"
            :validate-disabled="false"
          ></check-box-input>
        </div>
        <div class="col col-12 col-md-4">
          <check-box-input
            switcher
            v-model="secretEvent"
            label="Доступ только по ссылке"
            class="mb-2"
            :validate-disabled="false"
          ></check-box-input>
        </div>
        <div class="col col-12 col-md-4">
          <check-box-input
            switcher
            v-model="eventUploadAtAdd"
            v-if="
              eventReview &&
                eventArticleTypeChosen &&
                eventArticleTypeChosen.includes(2)
            "
            label="Загрузка файла со статьей сразу"
            class="mb-2"
            :validate-disabled="false"
          ></check-box-input>
        </div>
        <div class="col col-12 col-md-4">
          <check-box-input
            switcher
            v-model="draft"
            label="Черновик"
            class="mb-2"
            :validate-disabled="false"
          ></check-box-input>
        </div>
      </div>
    </validation-observer>
    <template v-if="!eventCustomLink">
      <div>
        <TitleBold center class="mb-2">Форма регистрации</TitleBold>
        <div class="d-flex w-100 d-md-none justify-content-center text-center">
          Редактирование формы доступно только в настольной версии
        </div>
        <div class="d-none d-md-block" v-if="renderEditFields">
          <div>
            <draggable
              v-model="fields"
              group="fields"
              @start="drag = true"
              @end="drag = false"
              class="row gy-2 mb-4 editable-fields"
              :class="{
                'drag-now': drag
              }"
              animation="200"
            >
              <div
                v-for="(element, key) in fields"
                :key="key"
                class="col col-12"
                :class="{
                  [`col-md-${element.col}`]: element.col,
                  'size-transition': editFieldModal !== null || createField,
                  'd-none': !isShown(element)
                }"
                @click="() => openModal(key, 'fields')"
                @dragstart="dragstartNow"
                @mouseover="hoverOn"
                @mouseout="hoverOff"
                @mouseleave="hoverOff"
              >
                <RegInput
                  v-bind="localize(element)"
                  edit
                  v-if="isShown(element)"
                ></RegInput>
              </div>
            </draggable>
            <div class="d-flex justify-content-center mb-5">
              <FilledButton color="dark" @click.native="startCreateField"
                >Новое поле</FilledButton
              >
            </div>
          </div>
          <TitleStrip center class="mb-2" v-if="deletedFields.length"
            >Удалённые поля</TitleStrip
          >
          <div class="row gy-2 mb-4 editable-fields">
            <div
              v-for="(element, key) in deletedFields"
              :key="key"
              class="col col-12"
              :class="{
                [`col-md-${element.col}`]: element.col,
                'size-transition': editFieldModal !== null || createField,
                'd-none': !isShown(element)
              }"
              @click="() => openModal(key, 'fields', true)"
              @dragstart="dragstartNow"
              @mouseover="hoverOn"
              @mouseout="hoverOff"
              @mouseleave="hoverOff"
            >
              <RegInput v-bind="localize(element)" edit></RegInput>
            </div>
          </div>
        </div>
      </div>
      <div>
        <TitleBold center class="mb-2">
          Документы
        </TitleBold>
        <div class="d-flex w-100 d-md-none justify-content-center text-center">
          Редактирование документов доступно только в настольной версии
        </div>
        <div class="d-none d-md-block">
          <div>
            <draggable
              v-model="documents"
              group="documents"
              @start="dragDocuments = true"
              @end="dragDocuments = false"
              class="row gy-2 mb-4 editable-fields"
              :class="{
                'drag-now': dragDocuments
              }"
              animation="200"
            >
              <div
                v-for="(element, key) in documents"
                :key="key"
                class="col col-12"
                :class="{
                  [`col-md-${element.col}`]: element.col,
                  'size-transition': editFieldModal !== null || createField
                }"
                @click="() => openModal(key, 'documents')"
                @dragstart="dragstartNow"
                @mouseover="hoverOn"
                @mouseout="hoverOff"
                @mouseleave="hoverOff"
              >
                <RegInput v-bind="localize(element)" edit></RegInput>
              </div>
            </draggable>
            <div class="d-flex justify-content-center mb-5">
              <FilledButton
                color="dark"
                @click.native="startCreateField('documents')"
                >Новый документ</FilledButton
              >
            </div>
          </div>
          <TitleStrip center class="mb-2" v-if="deletedDocuments.length"
            >Удалённые документы</TitleStrip
          >
          <div
            v-for="(element, key) in deletedDocuments"
            :key="key"
            class="col col-12"
            :class="{
              [`col-md-${element.col}`]: element.col,
              'size-transition': editFieldModal !== null || createField
            }"
            @click="() => openModal(key, 'documents', true)"
            @dragstart="dragstartNow"
            @mouseover="hoverOn"
            @mouseout="hoverOff"
            @mouseleave="hoverOff"
          >
            <RegInput v-bind="localize(element)" edit></RegInput>
          </div>
        </div>
      </div>
      <div>
        <ul class="list-unstyled">
          <li><span class="text-danger">*</span> - обязательное поле</li>
          <li>
            <SvgIcon color="current" icon="box-alt" height="16" /> - поле от
            типа мероприятия или участия
          </li>
          <li>
            <SvgIcon color="current" icon="table" height="16" /> - отображается
            в таблице
          </li>
        </ul>
      </div>
    </template>
    <TitleBold center>Секции</TitleBold>
    <SectionsEdit v-model="sections" class="mb-2" />
    <div class="d-flex justify-content-center mb-5">
      <FilledButton color="primary" class="btn-wide" @click.native="onSubmit">
        Сохранить
      </FilledButton>
    </div>
    <modal
      name="modal-field"
      @closed="onCloseModal"
      @opened="onOpenModal"
      draggable=".modal-header"
      adaptive
      height="auto"
      scrollable
      overlay-transition="short-fade"
      :click-to-close="false"
    >
      <validation-observer tag="div" class="modal-content" ref="modalObserver">
        <div class="modal-header">
          <h5 class="modal-title">Редактирование поля</h5>
          <button
            type="button"
            class="btn-close"
            @click="cancelChanges"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="nowField">
            <template v-if="!nowField.eventTypeRequired">
              <base-input
                label="Заголовок [ru]"
                v-model="nowField.label_ru"
                class="mb-2"
                required
                max-length="255"
                :validate-disabled="false"
              ></base-input>
              <base-input
                label="Заголовок [en]"
                v-model="nowField.label_en"
                class="mb-2"
                max-length="255"
                :validate-disabled="false"
              ></base-input>
              <base-input
                label="Подсказка [ru]"
                v-model="nowField.help_ru"
                class="mb-2"
                max-length="255"
                :validate-disabled="false"
              ></base-input>
              <base-input
                label="Подсказка [en]"
                v-model="nowField.help_en"
                class="mb-2"
                max-length="255"
                :validate-disabled="false"
              ></base-input>
              <radio-buttons-input
                v-if="nowFieldTypes.length > 1 && !nowField.eventTypeRequired"
                label="Тип поля"
                v-model="nowField.type"
                :items="nowFieldTypes"
                select
                one-value
                class="mb-2"
                :validate-disabled="false"
              ></radio-buttons-input>
              <base-input
                v-if="['text', 'textarea'].includes(nowField.type)"
                label="Максимальный размер содержимого"
                v-model="nowField.maxLength"
                type="number"
                :min="0"
                class="mb-2"
                help="Оставьте поле пустым для снятия ограничений"
                :validate-disabled="false"
              ></base-input>
              <template v-if="nowField.type === 'number'">
                <base-input
                  label="Максимальное значение"
                  v-model="nowField.max"
                  type="number"
                  class="mb-2"
                  help="Оставьте поле пустым для снятия ограничений"
                  :validate-disabled="false"
                ></base-input>
                <base-input
                  label="Минимальное значение"
                  v-model="nowField.min"
                  type="number"
                  class="mb-2"
                  help="Оставьте поле пустым для снятия ограничений"
                  :validate-disabled="false"
                ></base-input>
                <!--                  <base-input-->
                <!--                    label="Шаг исчисления"-->
                <!--                    v-model="nowField.step"-->
                <!--                    type="number"-->
                <!--                    class="mb-2"-->
                <!--                    placeholder="1"-->
                <!--                  ></base-input>-->
              </template>

              <base-input
                v-if="nowField.type === 'file'"
                label="Максимальный размер файла"
                v-model.number="nowField.maxSize"
                type="number"
                :max="15"
                class="mb-2"
                placeholder="10"
                help="В мегабайтах"
                :validate-disabled="false"
              ></base-input>
              <check-box-input
                v-if="!nowField.eventTypeRequired"
                :label="
                  nodeType === 'documents'
                    ? 'Обязательный документ'
                    : 'Обязательное поле'
                "
                v-model="nowField.required"
                class="mb-2"
                switcher
                :validate-disabled="false"
              ></check-box-input>
            </template>
            <label class="form-label w-100 mb-2" v-if="nodeType === 'fields'">
              Ширина поля
              <span class="badge bg-secondary">{{ nowField.col }}/12</span>
              <input
                type="range"
                class="form-range"
                :min="1"
                :max="12"
                :step="1"
                v-model.number="nowField.col"
              />
            </label>
            <check-box-input
              label="Отображать в таблице"
              v-model="nowField.table"
              class="mb-2"
              :validate-disabled="false"
            ></check-box-input>
            <radio-buttons-input
              v-if="
                nowField.type === 'checkbox_group' ||
                  (nowField.type === 'radio_buttons' && !nowField.select)
              "
              label="Внешний вид"
              v-model="fieldView"
              :items="fieldViews"
              select
              one-value
              class="mb-2"
              :validate-disabled="false"
            ></radio-buttons-input>
            <check-box-input
              v-if="nowField.type === 'radio_buttons'"
              label="Выпадающее меню"
              v-model="nowField.select"
              class="mb-2"
              :validate-disabled="false"
            ></check-box-input>
            <div
              v-if="
                ['radio_buttons', 'checkbox_group'].includes(nowField.type) &&
                  !nowField.eventTypeRequired
              "
              class="my-2"
            >
              <div><strong>Варианты ответа</strong></div>
              <draggable
                v-model="nowFieldItems"
                group="nowFieldItems"
                @start="dragModal = true"
                @end="dragModal = false"
                handle=".move-answer"
                class="answers-draggable"
                animation="200"
              >
                <div
                  v-for="(nowItem, key) in nowFieldItems"
                  :key="key"
                  class="mb-2 d-flex g-2"
                >
                  <div
                    class="d-flex flex-shrink-1 justify-content-center align-items-center"
                  >
                    <span class="input-group-text move-answer h-100">
                      <SvgIcon
                        icon="bars"
                        color="current"
                        height="16"
                      ></SvgIcon>
                    </span>
                  </div>
                  <div class="d-flex flex-column flex-grow-1 mx-2">
                    <base-input
                      type="text"
                      v-model="nowItem.label_ru"
                      class="mb-2"
                      placeholder="[ru]"
                      :max-length="256"
                      :validate-disabled="false"
                    />
                    <base-input
                      type="text"
                      v-model="nowItem.label_en"
                      placeholder="[en]"
                      :max-length="256"
                      :validate-disabled="false"
                    />
                  </div>
                  <div
                    class="d-flex flex-shrink-1 justify-content-center align-items-center"
                  >
                    <filled-button
                      color="danger"
                      @click="deleteRestoreChoice(key)"
                      v-if="!nowItem.deleted"
                    >
                      <SvgIcon
                        icon="trash"
                        color="current"
                        height="16"
                      ></SvgIcon>
                    </filled-button>
                    <filled-button
                      color="warning"
                      @click="deleteRestoreChoice(key)"
                      v-else
                    >
                      <SvgIcon
                        icon="redo"
                        color="current"
                        height="16"
                      ></SvgIcon>
                    </filled-button>
                  </div>
                </div>
                <!--                <base-input-->
                <!--                  v-for="(nowItem, key) in nowFieldItems"-->
                <!--                  :key="key"-->
                <!--                  type="text"-->
                <!--                  v-model="nowItem.label_ru"-->
                <!--                  class="mb-2"-->
                <!--                  placeholder="[ru]"-->
                <!--                  :validate-disabled="false"-->
                <!--                >-->
                <!--                  <template v-slot:prepend>-->
                <!--                    <span class="input-group-text move-answer">-->
                <!--                      <SvgIcon-->
                <!--                        icon="bars"-->
                <!--                        color="current"-->
                <!--                        height="16"-->
                <!--                      ></SvgIcon>-->
                <!--                    </span>-->
                <!--                  </template>-->
                <!--                  <template v-slot:append>-->
                <!--                    <base-input-->
                <!--                      type="text"-->
                <!--                      v-model="nowItem.label_en"-->
                <!--                      class="mb-2"-->
                <!--                      placeholder="[en]"-->
                <!--                      :validate-disabled="false"-->
                <!--                    ></base-input>-->
                <!--                    <filled-button-->
                <!--                      color="danger"-->
                <!--                      @click="deleteRestoreChoice(key)"-->
                <!--                      v-if="!nowItem.deleted"-->
                <!--                    >-->
                <!--                      <SvgIcon-->
                <!--                        icon="trash"-->
                <!--                        color="current"-->
                <!--                        height="16"-->
                <!--                      ></SvgIcon>-->
                <!--                    </filled-button>-->
                <!--                    <filled-button-->
                <!--                      color="warning"-->
                <!--                      @click="deleteRestoreChoice(key)"-->
                <!--                      v-else-->
                <!--                    >-->
                <!--                      <SvgIcon-->
                <!--                        icon="redo"-->
                <!--                        color="current"-->
                <!--                        height="16"-->
                <!--                      ></SvgIcon>-->
                <!--                    </filled-button>-->
                <!--                  </template>-->
                <!--                </base-input>-->
              </draggable>
              <div class="d-flex w-100 justify-content-center mb-2">
                <filled-button color="success" @click.native="addItem()">
                  <SvgIcon icon="plus" color="current" height="16"></SvgIcon>
                </filled-button>
              </div>
            </div>
            <div
              v-if="nowField.type === 'file' && !nowField.eventTypeRequired"
              class="my-2"
            >
              <div><strong>Типы файлов</strong></div>
              <base-input
                v-for="(nowItem, key) in nowFieldFileTypes"
                :key="key"
                type="text"
                :value="nowItem"
                max-length="127"
                @input="
                  e => {
                    nowFieldFileTypes[key] = e;
                    nowFieldFileTypes = [...nowFieldFileTypes];
                  }
                "
                class="mb-2"
                :validate-disabled="false"
              >
                <template v-slot:append>
                  <filled-button
                    color="danger"
                    @click="
                      nowFieldFileTypes = nowFieldFileTypes.filter(
                        (val, i) => i !== key
                      )
                    "
                  >
                    <SvgIcon icon="trash" color="current" height="16"></SvgIcon>
                  </filled-button>
                </template>
              </base-input>
              <div class="d-flex w-100 justify-content-center mb-2">
                <filled-button color="success" @click="addFileType">
                  <SvgIcon icon="plus" color="current" height="16"></SvgIcon>
                </filled-button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="btn-group" v-if="nowField">
            <template
              v-if="
                !createField &&
                  (!nowField.eventTypeRequired || !nowField.required)
              "
            >
              <button
                v-if="editFieldModalDeleted"
                type="button"
                class="btn btn-warning"
                @click="restoreField"
              >
                Восстановить
              </button>
              <button
                v-else
                type="button"
                class="btn btn-danger"
                @click="deleteField"
              >
                Удалить
              </button>
            </template>
            <button
              type="button"
              class="btn btn-secondary"
              @click="cancelChanges"
              v-text="createField ? 'Отмена' : 'Отмена изменений'"
            ></button>
            <button
              type="button"
              class="btn btn-primary"
              @click="closeModal"
              v-text="createField ? 'Добавить' : 'Изменить'"
            >
              Изменить
            </button>
          </div>
        </div>
      </validation-observer>
    </modal>
  </div>
</template>

<script>
import TitleBold from "../components/base/TitleBold";
import FilledButton from "../components/buttons/FilledButton";
import RegInput from "../components/inputs/RegInput";
import draggable from "vuedraggable";
import BaseInput from "../components/inputs/BaseInput";
import RadioButtonsInput from "../components/inputs/RadioButtonsInput";
import SvgIcon from "../components/base/SvgIcon";
import showdown from "showdown";
import EventShortInfo from "../components/events/EventShortInfo";
import TitleStrip from "../components/base/TitleStrip";
import CheckBoxInput from "../components/inputs/CheckBoxInput";
import { mapActions, mapGetters } from "vuex";
import CheckBoxGroupInput from "../components/inputs/CheckBoxGroupInput";
import SmallContainer from "../components/base/SmallContainer";
import { ValidationObserver } from "vee-validate";
import API from "../common/api/api.functions";
import SectionsEdit from "../components/events/SectionsEdit";
import loadingMixin from "../mixins/loadingMixin";
import UserSelect from "../components/inputs/UserSelect";
import localizeMixin from "../mixins/localizeMixin";
import EmailListInput from "../components/inputs/EmailListInput";

export default {
  name: "EventEdit",
  components: {
    EmailListInput,
    UserSelect,
    SectionsEdit,
    SmallContainer,
    CheckBoxGroupInput,
    CheckBoxInput,
    TitleStrip,
    EventShortInfo,
    RadioButtonsInput,
    BaseInput,
    RegInput,
    FilledButton,
    TitleBold,
    SvgIcon,
    draggable,
    ValidationObserver
  },
  mixins: [loadingMixin, localizeMixin],
  data: () => ({
    eventNameChosen: null,
    eventReview: false,
    eventArticleTypeChosen: null,
    eventSlug: null,
    oldHeaderImage: null,
    newHeaderImage: null,
    deletedHeaderImage: false,
    oldSmallImage: null,
    newSmallImage: null,
    srcSmallImage: null,
    deletedSmallImage: false,
    draft: true,
    eventUploadAtAdd: false,
    eventEscortFaces: false,
    secretEvent: false,
    eventCustomLink: null,
    eventDatesRequest: null,
    eventEmail: null,
    eventSite: null,
    eventDates: null,
    mdToHtml: null,
    eventTitleRu: null,
    eventTitleEn: null,
    eventDescriptionRu: null,
    eventDescriptionEn: null,
    dragModal: false,
    drag: false,
    dragDocuments: false,
    editFieldModal: null,
    editFieldModalDeleted: false,
    modalField: false,
    bufferField: null,
    createField: false,
    hovered: false,
    fieldViews: [
      {
        value: "",
        label: "-"
      },
      {
        value: "inline",
        label: "В линию"
      },
      {
        value: "button",
        label: "Кнопками"
      },
      {
        value: "inline_button",
        label: "В линию кнопками"
      }
    ],
    fieldTypesGroup: [
      [
        {
          value: "radio_buttons",
          label: "Радио-кнопки"
        },
        {
          value: "checkbox_group",
          label: "Группа чекбоксов"
        }
      ],
      [{ value: "checkbox", label: "Флажок" }],
      [
        { value: "text", label: "Текст" },
        { value: "textarea", label: "Многострочный текст" }
      ],
      [{ value: "number", label: "Целое число" }],
      [{ value: "float", label: "Вещественное число" }],
      [{ value: "email", label: "Email" }],
      [{ value: "url", label: "Ссылка" }],
      [{ value: "tel", label: "Телефон" }],
      [{ value: "date", label: "Дата" }],
      [{ value: "time", label: "Время" }],
      [{ value: "file", label: "Файл" }]
    ],
    nodeType: "fields",
    fields: [],
    deletedFields: [],
    documents: [],
    deletedDocuments: [],
    sectionSelected: null,
    sections: [],
    renderEditFields: true,
    admins: [],
    moderators: [],
    eventEmailList: []
  }),
  computed: {
    ...mapGetters([
      "eventNames",
      "eventArticleTypes",
      "defaultFields",
      "language"
    ]),
    eventSlugNow: {
      get() {
        return this.$route.params.slug || null;
      },
      set(val) {
        let newPath = { name: "EventEdit", params: { slug: val } };
        if (this.$router.resolve(newPath).href !== this.$route.path)
          this.$router.push(newPath);
      }
    },
    htmlOverflowHidden: {
      get() {
        return this.$store.state.htmlOverflowHidden;
      },
      set(val) {
        this.$store.commit("UPDATE_OVERFLOW", val);
      }
    },
    eventShortInfo() {
      let info = {
        title: this.eventTitle,
        slug: this.eventSlug,
        image: this.srcSmallImage
      };
      if (this.eventDates?.start) {
        info.dateStart = this.eventDates.start;
        if (
          this.eventDates?.end &&
          new Date(info.dateStart).toDateString() !==
            new Date(this.eventDates.end).toDateString()
        ) {
          info.dateEnd = this.eventDates.end;
        }
      }
      return info;
    },
    eventTitle: {
      get() {
        return this.language === "ru" ? this.eventTitleRu : this.eventTitleEn;
      },
      set(val) {
        if (this.language === "ru") this.eventTitleRu = val;
        else this.eventTitleEn = val;
      }
    },
    // headerTitle: {
    //   get() {
    //     return this.$store.state.headerTitle;
    //   },
    //   set(val) {
    //     return this.$store.commit("UPDATE_HEADER_TITLE", val);
    //   }
    // },
    eventHeaderImage() {
      return this.deletedHeaderImage
        ? null
        : this.newHeaderImage || this.oldHeaderImage;
    },
    eventSmallImage() {
      return this.deletedSmallImage
        ? null
        : this.newSmallImage || this.oldSmallImage;
    },
    eventDescriptionRuHtml() {
      if (this.mdToHtml) {
        return this.mdToHtml.makeHtml(this.eventDescriptionRu);
      }
      return null;
    },
    eventDescriptionEnHtml() {
      if (this.mdToHtml) {
        return this.mdToHtml.makeHtml(this.eventDescriptionEn);
      }
      return null;
    },
    fieldTypes() {
      return this.fieldTypesGroup.reduce((accumulator, val) => [
        ...(accumulator || []),
        ...val
      ]);
    },
    nowFieldTypes() {
      if (this.nodeType === "documents")
        return this.fieldTypesGroup.find(
          val => !!val.find(innerVal => innerVal.value === "file")
        );
      if (this.editFieldModal === null && !this.createField) return null;
      if (this.createField || this.nowField?.new) return this.fieldTypes;
      let nowType = this.nowField?.type;
      nowType = nowType ? nowType : "text";
      return this.fieldTypesGroup.find(
        val => !!val.find(innerVal => innerVal.value === nowType)
      );
    },
    nowField: {
      get() {
        if (this.createField) return this.bufferField;
        if (this.nodeType === "documents") {
          if (this.editFieldModalDeleted) {
            return this.deletedDocuments[this.editFieldModal] || null;
          }
          return this.documents[this.editFieldModal] || null;
        }
        if (this.editFieldModalDeleted) {
          return this.deletedFields[this.editFieldModal] || null;
        }
        return this.fields ? this.fields[this.editFieldModal] : null;
      },
      set(val) {
        if (this.createField) this.bufferField = val;
        if (this.nodeType === "documents") {
          if (this.editFieldModalDeleted) {
            this.deletedDocuments[this.editFieldModal] = val;
            this.deletedDocuments = [...this.deletedDocuments];
            return;
          }
          this.documents[this.editFieldModal] = val;
          this.documents = [...this.documents];
          return;
        }
        if (this.editFieldModalDeleted) {
          this.deletedFields[this.editFieldModal] = val;
          this.deletedFields = [...this.deletedFields];
          return;
        }
        this.fields[this.editFieldModal] = val;
        this.fields = [...this.fields];
      }
    },
    nowFieldItems: {
      get() {
        return this.nowField?.items ? this.nowField.items : [];
      },
      set(val) {
        this.nowField.items = val;
        this.nowField = { ...this.nowField };
      }
    },
    nowFieldFileTypes: {
      get() {
        return this.nowField?.fileTypes ? this.nowField.fileTypes : [];
      },
      set(val) {
        this.nowField.fileTypes = val;
        this.nowField = { ...this.nowField };
      }
    },
    fieldView: {
      get() {
        let nowField = this.nowField;
        let outStringArray = [];
        if (nowField.inline) outStringArray = [...outStringArray, "inline"];
        if (nowField.button) outStringArray = [...outStringArray, "button"];
        return outStringArray.join("_");
      },
      set(val) {
        let nowField = this.nowField;
        delete nowField.inline;
        delete nowField.button;
        delete nowField.buttonGroup;
        delete nowField.color;
        let nowView = val.split("_");
        if (nowView.includes("inline")) {
          nowField.inline = "between";
        }
        if (nowView.includes("button")) {
          nowField.button = true;
          nowField.buttonGroup = true;
          nowField.color = "outline-primary brand-style";
        }
        this.nowField = nowField;
      }
    }
  },
  async mounted() {
    this.loading++;
    this.mdToHtml = new showdown.Converter();
    // this.oldHeaderImage = this.$store.state.headerImage;
    // this.oldSmallImage = this.srcSmallImage;
    // this.fields = [];

    if (this.eventSlugNow) {
      let eventResponse = await API.getEventAdminDetails(this.eventSlugNow)
        .then(res => res.data)
        .catch(
          () => {
            this.notFound = true;
            this.loading--;
          }
          // this.$toast.error(JSON.stringify(error?.response?.data || {}))
        );
      if (this.notFound) return;
      await this.updateEventObject(eventResponse);
      this.loading--;
      return;
    }
    if (!this.$store.getters.userIsAdmin) {
      this.notFound = true;
      this.loading--;
      return;
    }
    await this.defaultFields.forEach(async val => {
      const formattedField = await this.fieldToFront({
        field: val,
        forUser: false
      });
      this.fields = [...this.fields, formattedField];
    });
    this.loading--;
  },
  watch: {
    eventTitle(val) {
      this.$store.commit("UPDATE_HEADER_TITLE", val);
    },
    async eventHeaderImage(val) {
      if (!val) {
        this.$store.commit("UPDATE_HEADER_IMAGE", null);
      } else if (val[0]?.type) {
        const toBase64 = file =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });
        this.$store.commit("UPDATE_HEADER_IMAGE", await toBase64(val[0]));
      } else this.$store.commit("UPDATE_HEADER_IMAGE", val);
    },
    async eventSmallImage(val) {
      if (!val) {
        this.srcSmallImage = null;
      } else if (val[0]?.type) {
        const toBase64 = file =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });
        this.srcSmallImage = await toBase64(val[0]);
      } else this.srcSmallImage = val;
    },
    eventNameChosen() {
      this.renderEditFields = false;
      this.$nextTick(() => (this.renderEditFields = true));
    },
    eventArticleTypeChosen() {
      this.renderEditFields = false;
      this.$nextTick(() => (this.renderEditFields = true));
    }
  },
  methods: {
    ...mapActions(["fieldToFront", "fieldToBack"]),
    deleteRestoreChoice(key) {
      if (this.nowFieldItems[key].id) {
        this.nowFieldItems[key].deleted = !this.nowFieldItems[key].deleted;
        this.nowFieldItems = [...this.nowFieldItems];
      } else {
        this.nowFieldItems = this.nowFieldItems.filter(
          (val, index) => index !== key
        );
      }
    },
    async sendRequest() {
      const formData = new FormData();
      // const toRightUUID = /^(.{8})-?(.{4})-?(.{4})-?(.{4})-?(.{12})$/gim;
      let eventInfo = {
        title: this.eventTitleRu,
        title_ru: this.eventTitleRu,
        title_en: this.eventTitleEn,
        slug: this.eventSlug,
        description: this.eventDescriptionRu,
        description_ru: this.eventDescriptionRu,
        description_en: this.eventDescriptionEn,
        date_begin_event: this.eventDates.start.toJSON().split("T")[0],
        date_end_event: this.eventDates.end.toJSON().split("T")[0],
        date_begin_request: this.eventDatesRequest.start.toJSON().split("T")[0],
        date_end_request: this.eventDatesRequest.end.toJSON().split("T")[0],
        draft: this.draft,
        only_link_access: this.secretEvent,
        review: this.eventReview,
        event_type: this.eventNameChosen,
        participation_type: this.eventArticleTypeChosen,
        escort_faces: this.eventEscortFaces,
        article_on_start: this.eventUploadAtAdd,
        admins: this.admins
          // .filter(val => toRightUUID.test(val.username))
          // .map(val => ({
          //   username: val.username.replace(toRightUUID, "$1-$2-$3-$4-$5")
          // }))
          .map(val => val.username),
        moderators: this.moderators
          // .filter(val => toRightUUID.test(val.username))
          // .map(val => ({
          //   username: val.username.replace(toRightUUID, "$1-$2-$3-$4-$5")
          // }))
          .map(val => val.username),
        fields: []
      };
      let order_counter = 0;
      const workForFields = (fields, additional = {}, isDocument = false) => {
        for (const field of fields) {
          eventInfo.fields.push(
            this.fieldToBack(
              {
                ...field,
                order: order_counter++,
                hidden: !this.isShown(field),
                ...additional
              },
              isDocument
            )
          );
        }
      };
      workForFields(this.fields, { deleted: false });
      workForFields(this.deletedFields, { deleted: true });
      order_counter = 0;
      workForFields(this.documents, { deleted: false }, true);
      workForFields(this.deletedDocuments, { deleted: true }, true);

      eventInfo.fields = await Promise.all(eventInfo.fields);
      eventInfo.sections = this.sections.map((val, index) => ({
        ...val,
        order: index
      }));
      if (this.eventEmail) eventInfo.email = this.eventEmail;
      eventInfo.emails = this.eventEmailList.filter(val => !!val);
      if (this.eventSite) eventInfo.site = this.eventSite;
      if (this.eventCustomLink)
        eventInfo.application_link = this.eventCustomLink;
      if (this.newSmallImage && this.newSmallImage[0])
        formData.append("image", this.newSmallImage[0]);
      else if (this.deletedSmallImage) eventInfo.image = null;
      if (this.newHeaderImage && this.newHeaderImage[0])
        formData.append("big_image", this.newHeaderImage[0]);
      else if (this.deletedHeaderImage) eventInfo.big_image = null;
      formData.append("data", JSON.stringify(eventInfo));
      // formData.append(
      //   "admins",
      //   JSON.stringify(
      //     this.admins.map(val => ({
      //       username: val.username
      //     }))
      //   )
      // );
      let eventResponse = null;
      const actionFetch = this.eventSlugNow
        ? API.eventEdit(this.eventSlugNow, formData)
        : API.eventCreate(formData);
      eventResponse = await actionFetch
        .then(res => {
          this.deletedHeaderImage = false;
          this.deletedSmallImage = false;
          this.newHeaderImage = null;
          this.newSmallImage = null;
          return res.data;
        })
        .catch(error =>
          this.$toast.error(JSON.stringify(error?.response?.data || {}))
        );

      await this.updateEventObject(eventResponse);
    },
    async updateEventObject(eventResponse) {
      if (eventResponse && eventResponse.slug) {
        this.eventTitleRu = eventResponse.title_ru;
        this.eventTitleEn = eventResponse.title_en;
        this.eventSlug = eventResponse.slug;
        this.eventSlugNow = this.eventSlug;
        this.eventDescriptionRu = eventResponse.description_ru;
        this.eventDescriptionEn = eventResponse.description_en;
        this.eventSite = eventResponse.site;
        this.eventDates = {};
        this.eventDates.start = new Date(eventResponse.date_begin_event);
        this.eventDates.end = new Date(eventResponse.date_end_event);
        this.eventDatesRequest = {};
        this.eventDatesRequest.start = new Date(
          eventResponse.date_begin_request
        );
        this.eventDatesRequest.end = new Date(eventResponse.date_end_request);
        this.eventEmail = eventResponse.email;
        this.eventEmailList = eventResponse.emails;
        this.draft = eventResponse.draft;
        this.secretEvent = eventResponse.only_link_access;
        this.eventReview = eventResponse.review;
        this.eventNameChosen = eventResponse.event_type;
        this.eventArticleTypeChosen = eventResponse.participation_type;
        this.eventEscortFaces = eventResponse.escort_faces;
        this.eventUploadAtAdd = eventResponse.article_on_start;
        this.eventCustomLink = eventResponse.application_link;
        this.sections = eventResponse.sections;
        this.admins = eventResponse.admins;
        this.moderators = eventResponse.moderators;

        const fieldsBuffer = {
          fields: [],
          deletedFields: [],
          documents: [],
          deletedDocuments: []
        };
        for (const field of await Promise.all(
          eventResponse.fields.map(field =>
            this.fieldToFront({
              field,
              forUser: false
            })
          )
        )) {
          let fieldName = "";
          if (!field.document) fieldName = "fields";
          else fieldName = "documents";
          if (field.deleted)
            fieldName = `deleted${this.capitalizeFirstLetter(fieldName)}`;
          fieldsBuffer[fieldName].push(field);
        }
        for (const field of Object.entries(fieldsBuffer)) {
          this[field[0]] = field[1];
        }
        this.srcSmallImage = eventResponse.image;
        this.oldSmallImage = this.srcSmallImage;
        this.$store.commit("UPDATE_HEADER_IMAGE", eventResponse.big_image);
        this.oldHeaderImage = this.$store.state.headerImage;
      }
    },
    isShown(element) {
      if (!element.eventTypeRequired) return true;
      if (element.participationTypes?.length) {
        let participationTypesSet = new Set([...element.participationTypes]);
        let intersection = [...(this.eventArticleTypeChosen || [])].filter(x =>
          participationTypesSet.has(x)
        );
        return !!intersection.length;
      }
      if (element.eventTypes?.length) {
        let eventTypesSet = new Set([...element.eventTypes]);
        let intersection = [...([this.eventNameChosen] || [])].filter(x =>
          eventTypesSet.has(x)
        );
        return !!intersection.length;
      }
      return true;
    },
    async onSubmit() {
      for (const nowRef of Object.values(this.$refs.observer.refs)) {
        if (nowRef.disabled) continue;
        nowRef.setFlags({ dirty: true });
      }
      let validation = await this.$refs.observer.validate();
      if (validation) {
        this.$toast.success("Успешная форма!");
        await this.sendRequest();
        return;
      }
      this.$toast.error("Исправьте ошибки!");
      let refs = this.$refs.observer.refs;
      for (const ref of Object.values(refs)) {
        if (ref.errors.length) {
          ref.$el.scrollIntoView({ block: "center", behavior: "smooth" });
          break;
        }
      }
    },
    resetHeaderImage() {
      this.newHeaderImage = null;
      this.deletedHeaderImage = false;
    },
    deleteHeaderImage() {
      this.newHeaderImage = null;
      this.deletedHeaderImage = true;
    },
    resetSmallImage() {
      this.newSmallImage = null;
      this.deletedSmallImage = false;
    },
    deleteSmallImage() {
      this.newSmallImage = null;
      this.deletedSmallImage = true;
    },
    onCloseModal() {
      this.editFieldModal = null;
      this.createField = false;
      this.bufferField = null;
      this.htmlOverflowHidden = false;
    },
    onOpenModal() {
      this.htmlOverflowHidden = true;
    },
    addItem() {
      this.nowFieldItems = [
        ...this.nowFieldItems,
        {
          label: "Вариант ответа",
          value: null
        }
      ];
    },
    addFileType() {
      this.nowFieldFileTypes = [...this.nowFieldFileTypes, null];
    },
    startCreateField(nodeType = "fields") {
      this.createField = true;
      this.nodeType = nodeType;
      if (nodeType === "documents")
        this.bufferField = {
          label: "Новый документ",
          new: true,
          col: 4,
          type: "file"
        };
      else
        this.bufferField = {
          label: "Новое поле",
          new: true,
          col: 4,
          type: "text"
        };
      this.$modal.show("modal-field");
    },
    openModal(index, nodeType, deleted = false) {
      this.editFieldModal = index;
      this.editFieldModalDeleted = deleted;
      this.nodeType = nodeType;
      this.bufferField = JSON.parse(JSON.stringify(this.nowField));
      this.$modal.show("modal-field");
    },
    async closeModal() {
      for (const nowRef of Object.values(this.$refs.modalObserver.refs)) {
        if (nowRef.disabled) continue;
        nowRef.setFlags({ dirty: true });
      }
      let validation = await this.$refs.modalObserver.validate();
      if (!validation) {
        this.$toast.error("Исправьте ошибки!");
      } else {
        if (this.createField) {
          if (this.nodeType === "documents")
            this.documents = [...this.documents, this.bufferField];
          else this.fields = [...this.fields, this.bufferField];
        }
        this.$modal.hide("modal-field");
      }
    },
    cancelChanges() {
      if (!this.createField) this.nowField = { ...this.bufferField };
      this.$modal.hide("modal-field");
    },
    deleteField() {
      const deleteField = this.editFieldModal;
      this.$modal.hide("modal-field");
      let bufferField = {};
      if (this.nodeType === "documents") {
        bufferField = JSON.parse(
          JSON.stringify(
            this.documents.find((value, index) => index === deleteField)
          )
        );
        this.documents = this.documents.filter(
          (value, index) => index !== deleteField
        );
        if (!bufferField.new) this.deletedDocuments.push(bufferField);
      } else {
        bufferField = JSON.parse(
          JSON.stringify(
            this.fields.find((value, index) => index === deleteField)
          )
        );
        this.fields = this.fields.filter(
          (value, index) => index !== deleteField
        );
        if (!bufferField.new) this.deletedFields.push(bufferField);
      }
    },
    restoreField() {
      const restoreField = this.editFieldModal;
      this.$modal.hide("modal-field");
      let bufferField = {};
      if (this.nodeType === "documents") {
        bufferField = JSON.parse(
          JSON.stringify(
            this.deletedDocuments.find((value, index) => index === restoreField)
          )
        );
        this.deletedDocuments = this.deletedDocuments.filter(
          (value, index) => index !== restoreField
        );
        this.documents.push(bufferField);
      } else {
        bufferField = JSON.parse(
          JSON.stringify(
            this.deletedFields.find((value, index) => index === restoreField)
          )
        );
        this.deletedFields = this.deletedFields.filter(
          (value, index) => index !== restoreField
        );
        this.fields.push(bufferField);
      }
    },
    hoverOn() {
      this.hovered = true;
    },
    hoverOff() {
      this.hovered = false;
    },
    dragstartNow() {}
  }
};
</script>

<style lang="scss">
.no-select {
  &,
  * {
    user-select: none;
  }
}

.editable-fields {
  & > div {
    position: relative;

    &.sortable-chosen {
      background: theme-color("white");
      border-radius: $border-radius;
    }

    &.sortable-ghost {
      background: rgba(theme-color("primary"), 0.1);
      border-radius: $border-radius;
    }
  }

  &:not(.drag-now) {
    //background: #e35050;

    & > div {
      $color: theme-color("warning");

      & > div {
        position: relative;
        user-select: none;

        &:hover {
          cursor: pointer;
        }

        &::after {
          position: absolute;
          content: "Изменить";
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          border: 1px solid hard-dark($color);
          border-radius: $border-radius;
          background: $color;
          color: color-contrast(hard-dark($color));
          text-transform: uppercase;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          opacity: 0;
          user-select: none;
          z-index: 4;
        }
      }

      &:not([style]),
      &[style=""] {
        &:hover {
          & > div::after {
            opacity: 1;
            transition: all 0.3s;
          }
        }
      }
    }
  }
}

.move-answer {
  cursor: grab;
}

.answers-draggable {
  .sortable-ghost {
    position: relative;

    &::after {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: $border-radius;
      background: rgba(theme-color("primary"), 0.1);
      z-index: 100;
    }
  }
}
</style>
