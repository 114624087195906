<template>
  <span
    class="badge rounded-pill d-inline-flex align-items-center mb-1 me-1 tr-short-fade text-wrap"
    :class="{
      'bg-primary': !closeHovered,
      'bg-danger': closeHovered
    }"
  >
    <div class="d-flex align-items-center me-1">
      <avatar :src="value.avatar_small" size="16px"></avatar>
    </div>
    {{ value.full_name }} [{{ value.username }}]
    <span
      class="pointer-event"
      @click.prevent="() => $emit('delete')"
      @mouseenter="() => (closeHovered = true)"
      @mouseleave="() => (closeHovered = false)"
    >
      <svg-icon
        icon="close"
        color="current"
        hover="current"
        height="16"
        class="ms-1"
      ></svg-icon>
    </span>
  </span>
</template>

<script>
import Avatar from "./Avatar";
import SvgIcon from "./SvgIcon";
export default {
  name: "UserListBadgeNode",
  components: { Avatar, SvgIcon },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    closeHovered: false
  })
};
</script>
