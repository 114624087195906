<template>
  <span>
    <popper
      @keydown="moveSelect"
      my-listeners
      ref="popper"
      v-click-outside="popperListener"
    >
      <base-input
        v-on="inputListeners"
        v-model="searchInput"
        :label="label"
      ></base-input>
      <template v-slot:popper>
        <div class="card-body" v-if="loadUsers">
          <div class="card-text text-center">
            Загрузка пользователей
          </div>
        </div>
        <div
          class="card-body"
          v-else-if="!resultSearch || !resultSearch.length"
        >
          <div class="card-text text-center">
            Пользователи не найдены
          </div>
        </div>
        <div class="list-group list-group-flush" v-else>
          <a
            href="#"
            class="list-group-item  list-group-item-action"
            :class="{ active: selected === key }"
            v-for="(listNode, key) in resultSearch"
            :key="`search_${key}`"
            @click.prevent="() => addToUsers(key)"
          >
            <UserListNode :value="listNode"></UserListNode>
          </a>
        </div>
      </template>
    </popper>
    <transition-group name="list" tag="div">
      <UserListBadgeNode
        :value="listNode"
        v-for="(listNode, key) in value"
        :key="`selected_${key}`"
        @delete="() => removeFromUsers(key)"
      ></UserListBadgeNode>
    </transition-group>
  </span>
</template>

<script>
import BaseInput from "./BaseInput";
import Popper from "../base/Popper";
import API from "../../common/api/api.functions";
import ClickOutside from "vue-click-outside";
import UserListNode from "../base/UserListNode";
import { debounce } from "lodash";
import UserListBadgeNode from "../base/UserListBadgeNode";

export default {
  name: "UserSelect",
  components: { UserListBadgeNode, UserListNode, Popper, BaseInput },
  props: {
    value: {
      type: Array,
      default: () => [],
      required: true
    },
    filters: {
      type: Object,
      default: () => {}
    },
    label: {
      type: String,
      default: () => "Пользователи"
    }
  },
  data: () => ({
    searchInput: null,
    resultSearch: [],
    selectedSummary: [],
    selected: 0,
    inputListeners: {},
    popperListener: () => null,
    loadUsers: true
  }),
  mounted() {
    this.inputListeners = {
      focus: async () => {
        this.$refs.popper.show();
        await this.getUsers();
      }
    };
    this.popperListener = this.$refs.popper.hide;
  },
  computed: {
    usernames() {
      return this.value.map(val => val.username);
    }
  },
  methods: {
    addToUsers(key = null) {
      if (key === null) {
        key = this.selected;
      }
      this.selected = 0;
      this.$emit("input", [...this.value, this.resultSearch[key]]);
    },
    removeFromUsers(key) {
      const selectedSummary = this.value;
      selectedSummary.splice(key, 1);
      this.$emit("input", selectedSummary);
    },
    debounceGetUsers: debounce(async function() {
      await this.getUsers();
    }, 1000),
    async getUsers() {
      let filters = {};
      if (this.usernames && this.usernames.length) {
        filters = { ...filters, username__not_in: this.usernames };
      }
      if (this.searchInput) {
        filters = { ...filters, search: this.searchInput };
      }
      if (this.filters && Object.keys(this.filters).length)
        filters = { ...filters, ...this.filters };
      this.resultSearch = await API.getUserList(1, 5, filters)
        .then(val => val.data?.results || [])
        .catch(e => this.$toast.error(e.response?.text));
      this.loadUsers = false;
    },
    moveSelect(event) {
      let resultSearchLength = this.resultSearch.length;
      if (event.code.includes("ArrowDown")) {
        if (this.selected + 1 > resultSearchLength - 1) {
          this.selected = 0;
          return;
        }
        this.selected += 1;
        return;
      }
      if (event.code.includes("ArrowUp")) {
        if (this.selected - 1 < 0) {
          this.selected = resultSearchLength - 1;
          return;
        }
        this.selected -= 1;
      }
      if (event.code.includes("Enter")) {
        this.addToUsers();
      }
    }
  },
  watch: {
    usernames() {
      this.loadUsers = true;
      this.debounceGetUsers();
    },
    searchInput() {
      this.loadUsers = true;
      this.debounceGetUsers();
    }
  },
  directives: {
    ClickOutside
  }
};
</script>
