<template>
  <div class="row gy-0 ">
    <div class="col-12 col-md-9">
      <div class="card">
        <div class="card-header text">
          {{ $t("event.section.edit.title") }}
        </div>
        <div class="card-body">
          <div class="card-text">
            <validation-observer ref="observerSection">
              <div v-if="selectedSectionIndex !== null">
                <base-input
                  :label="`${$t('event.fields.title')} [ru]`"
                  v-model="selectedSectionObject.title_ru"
                  :validate-disabled="false"
                  required
                  max-length="300"
                ></base-input>
                <base-input
                  :label="`${$t('event.fields.title')} [en]`"
                  v-model="selectedSectionObject.title_en"
                  :validate-disabled="false"
                  max-length="300"
                ></base-input>
                <base-input
                  :label="`${$t('event.fields.shortDescription')} [ru]`"
                  v-model="selectedSectionObject.short_description_ru"
                  :validate-disabled="false"
                  required
                  max-length="300"
                ></base-input>
                <base-input
                  :label="`${$t('event.fields.shortDescription')} [en]`"
                  v-model="selectedSectionObject.short_description_en"
                  :validate-disabled="false"
                  max-length="300"
                ></base-input>
                <base-input
                  :label="`${$t('event.fields.description')} [ru]`"
                  v-model="selectedSectionObject.description_ru"
                  type="textarea"
                  :validate-disabled="false"
                ></base-input>
                <base-input
                  :label="`${$t('event.fields.description')} [en]`"
                  v-model="selectedSectionObject.description_en"
                  type="textarea"
                  :validate-disabled="false"
                ></base-input>
                <title-strip center class="my-2" v-if="descriptionHtmlRu">
                  {{ $t("event.fields.descriptionHtml") }} [ru]
                </title-strip>
                <div v-html="descriptionHtmlRu"></div>
                <title-strip center class="my-2" v-if="descriptionHtmlEn">
                  {{ $t("event.fields.descriptionHtml") }} [en]
                </title-strip>
                <div v-html="descriptionHtmlEn"></div>
              </div>
              <div v-else>
                {{ $t("event.section.edit.choose") }}
              </div>
            </validation-observer>
          </div>
        </div>
        <div
          class="card-footer d-flex justify-content-end flex-wrap"
          v-if="selectedSectionIndex !== null"
        >
          <filled-button
            color="danger"
            class="mb-2 me-2"
            @click="deleteRestoreSection"
            v-if="!selectedSectionObject.deleted"
            >{{ $t("event.actions.delete") }}</filled-button
          >
          <filled-button
            class="mb-2 me-2"
            color="warning"
            @click="deleteRestoreSection"
            v-else
            >{{ $t("event.actions.restore") }}</filled-button
          >
          <filled-button
            class="mb-2 me-2"
            color="secondary"
            @click="() => chooseSection(selectedSectionIndex)"
            >{{ $t("event.actions.cancel") }}</filled-button
          >
          <filled-button
            class="mb-2 me-2"
            color="success"
            @click="saveSection"
            >{{ $t("event.actions.save") }}</filled-button
          >
        </div>
      </div>
    </div>
    <div
      class="row row-cols-1 gy-0 justify-content-center col col-12 col-md-3 mx-0 d-flex flex-column"
    >
      <draggable
        :value="value"
        group="nowFieldItems"
        @start="
          selectedSectionIndex !== null
            ? chooseSection(selectedSectionIndex)
            : null
        "
        @input="e => $emit('input', e)"
        animation="200"
        class="d-none d-md-block flex-grow-1"
      >
        <div
          class="col d-flex justify-content-center mb-2"
          v-for="(section, key) in value"
          :key="key"
        >
          <Section
            :section="section"
            :class="{
              active: key === selectedSectionIndex
            }"
            @click="() => chooseSection(key)"
          />
        </div>
      </draggable>
      <div
        class="col d-flex d-md-none justify-content-center mb-2"
        v-for="(section, key) in value"
        :key="key"
      >
        <Section
          :section="section"
          :class="{
            active: key === selectedSectionIndex
          }"
          @click="() => chooseSection(key)"
        />
      </div>
      <div class="d-flex justify-content-center flex-shrink-1">
        <filled-button color="dark" @click="chooseSection(-1)"
          ><svg-icon icon="plus" height="16" color="current"></svg-icon>
        </filled-button>
      </div>
    </div>
  </div>
</template>

<script>
import Section from "./Section";
import BaseInput from "../inputs/BaseInput";
import FilledButton from "../buttons/FilledButton";
import { ValidationObserver } from "vee-validate";
import draggable from "vuedraggable";
import SvgIcon from "../base/SvgIcon";
import showdown from "showdown";
import TitleStrip from "../base/TitleStrip";

export default {
  name: "SectionsEdit",
  components: {
    TitleStrip,
    FilledButton,
    BaseInput,
    Section,
    ValidationObserver,
    draggable,
    SvgIcon
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    selectedSectionObject: null,
    selectedSectionIndex: null,
    mdToHtml: null
  }),
  mounted() {
    this.mdToHtml = new showdown.Converter();
  },
  computed: {
    descriptionHtmlRu() {
      if (this.mdToHtml && this.selectedSectionIndex != null) {
        return this.mdToHtml.makeHtml(
          this.selectedSectionObject.description_ru
        );
      }
      return null;
    },
    descriptionHtmlEn() {
      if (this.mdToHtml && this.selectedSectionIndex != null) {
        return this.mdToHtml.makeHtml(
          this.selectedSectionObject.description_en
        );
      }
      return null;
    }
  },
  methods: {
    chooseSection(index) {
      if (this.selectedSectionIndex === index && index !== null) {
        this.selectedSectionIndex = this.selectedSectionObject = null;
      } else {
        this.selectedSectionIndex = null;
        this.$nextTick(() => {
          this.selectedSectionIndex = index;
          if (index !== -1) {
            this.selectedSectionObject = {
              ...this.value[index]
            };
          } else {
            this.selectedSectionObject = {};
          }
        });
      }
    },
    deleteRestoreSection() {
      if (this.selectedSectionObject.id) {
        this.selectedSectionObject.deleted = !this.selectedSectionObject
          .deleted;
        this.saveSection();
      } else {
        let oldIndex = this.selectedSectionIndex;
        this.chooseSection(this.selectedSectionIndex);
        this.$emit(
          "input",
          this.value.filter((value, index) => index !== oldIndex)
        );
      }
    },
    async saveSection() {
      for (const nowRef of Object.values(this.$refs.observerSection.refs)) {
        if (nowRef.disabled) continue;
        nowRef.setFlags({ dirty: true });
      }
      let validation = await this.$refs.observerSection.validate();
      if (validation) {
        this.selectedSectionObject.title = this.selectedSectionObject.title_ru;
        this.selectedSectionObject.short_description = this.selectedSectionObject.short_description_ru;
        this.selectedSectionObject.description = this.selectedSectionObject.description_ru;
        let newValue = JSON.parse(JSON.stringify(this.value));
        if (this.selectedSectionIndex === -1) {
          newValue = [...newValue, this.selectedSectionObject];
        } else {
          newValue[this.selectedSectionIndex] = this.selectedSectionObject;
        }
        this.chooseSection(this.selectedSectionIndex);
        this.$emit("input", newValue);
      } else {
        this.$toast.error("Исправьте ошибки!");
        let refs = this.$refs.observerSection.refs;
        for (const ref of Object.values(refs)) {
          if (ref.errors.length) {
            ref.$el.scrollIntoView({ block: "center", behavior: "smooth" });
            break;
          }
        }
      }
    }
  }
};
</script>

<style lang="scss">
.section-title-choose {
  font-size: 1.5rem;
  font-weight: bold;
}
</style>
