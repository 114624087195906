<template>
  <div class="d-flex w-100">
    <div class="me-2">
      <avatar :src="value.avatar_small"></avatar>
    </div>
    <div class="flex-grow-1">
      <div class="fw-bold">{{ value.full_name }}</div>
      <div>
        <span class="fw-bold">ID: </span
        ><span class="font-monospace">{{ value.username }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "./Avatar";
export default {
  name: "UserListNode",
  components: { Avatar },
  props: {
    value: {
      type: Object,
      required: true
    }
  }
};
</script>
